.filterColumn{
  display: flex;
  flex-direction: row;
}

.centerText{
  position: absolute;
}
.section{
  display: flex;
  flex-direction: column;
  margin: 0px 8px 8px 8px;
}

.buttonsGroup{
  display: flex;
  flex-direction: row
}

.contentCenter{
  justify-content: center
}
.directionColumn{
  display: flex;
  flex-direction: column;
}
