.diablogWrapper{
 display: flex;
 justify-content: center;
 margin-bottom: 30px;
 flex-direction: column;
  min-width: 500px;
}

.dialogRowEmp{
  display: flex;
  justify-content: flex-start;
}

.saveButton{
  display: flex;
  justify-content: center;
}

.margin{
  margin-top: 10px;
}