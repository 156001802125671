.login{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.loginContainer{
  margin-top: 15%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.logo{
  width: 80px;
  height: 80px
}

.img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.errorText{
  color: red;
  font-size: 16px;
  margin-top: 16px;
  min-height: 18px;
}