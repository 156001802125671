.full-width {
    width: 90%;
    display: block !important;
    column-count: 3;
    column-width: 150px;
}

.checkbox-label {
    display: inline-block;
    width: 150px;
}

.save-button {
    margin: 10px !important;
    text-align: center;
}

.content {
    text-align: center;
}