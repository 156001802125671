.inputField {
    margin: 10px;
    width: 60px;
}


.selectBox{
    margin: 10px;
    width: 100px;
}

.icon {
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
}

.center{
    text-align: center;
}

.dialogRowSmall{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: baseline;
    min-width: 250px;
}
.dialogRow{
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: row;
    min-width: 500px;
}
.dialogCell{
    width: 100px;
}
.dialogCellLarge{
    width: 150px;
}

.directionColumn{
    display: flex;
    flex-direction: column;
}

.directionRow{
    display: flex;
    flex-direction: row;
}

.marginRight{
    margin-right: 16px;
}
.prices{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;

    .edit-icon {
        margin-bottom: 10px;
    }
}

.checkBox{
    margin-left: 14px
}

.paymentName{
    width: 85px
}

.sign{
    width: 20px;
    text-align: center;
}
.filters{
    display: flex;
    border-right-width: 1px;
    border-right-style: solid;
    border-color: black;
    align-items: center;
    padding-right: 64px;
}

.sum{
    min-width: 250px;
}

.alignCenter{
    align-items: center;
}
.states{
    margin-left: 32px;
}

.invisible{
    width: 80px;
}

.invisibleBalance{
    width: 28px;
    height: 20px;
}

.pricesCell{
    display: flex;
    justify-content: flex-end;
}

.newOrder{
    display: flex;
    flex-direction: column
}

.orderDetails{
    display: flex;
    flex-direction: row
}

.checkBoxsGroup{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 0px;
}

.text-field {
    margin: 20px !important;
    width: 200px;
}