body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.pagination {
  display: inline-block;
  list-style: none;
  width: 100%;
  margin: 20px 0px 0px 0px;
  padding-left: 0px;
}

.pagination li {
  float: left;
  padding: 8px 16px;
  border: 1px solid #ddd;
}

.pagination li.active {
  background-color: #3f51b5;
}

.pagination li.active a {
  color: white;
}

.pagination li a {
  color: black;
  text-decoration: none;
}

.pagination li:hover:not(.active) {
  background-color: #ddd;
}
html {
  height: 100%;
  width: 100%;
}

body {
  height: calc(100% - 48px);
  width: 100%;
  margin: 0px !important;
}

#root {
  width: 100%;
  height: 100%;
}

input[type='date']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
}

.addButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
