
.form-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.text-field {
  margin: 20px !important;
  width: 200px;
}

.select {
  margin: 20px !important;
  width: 280px;
}

.container {
  position: relative;
}

.suggestions-container-open {
  position: absolute;
  z-index: 1;
  margin-top: 5;
  width: 200px;
}

.suggestion {
  display: block;
}

.suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  cursor: pointer;
}

.ordered {
  font-weight: 900;
}

.centerFlex {
  display: flex;
  justify-content: center;
}

.flexRow {
  display: flex;
  flex-flow: row;
}
.flexColumn {
  display: flex;
  flex-flow: column;
}
.property {
  min-width: 90px;
  padding: 4px;
}

.propertyValue {
  padding: 4px;
}
