.image{
    max-height: 500px;
    max-width: 500px;
    object-fit: contain;
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttons{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 16px;
}
.infoText{
  margin: 8px 0px;
  font-size: 18px;
  font-weight: 600;
}